import { useI18n } from 'vue-i18n'
export function convertDateFormat(inputDate) {
    const date = new Date(inputDate);
    
    const year = date.getFullYear() % 100;
    const month = date.getMonth() + 1;
    const day = date.getDate();
    
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
    
    return `${formattedDay}-${formattedMonth}-${year}`;
}

export function convertDateFormatYYYYMMDD(inputDate) {
    if (typeof inputDate === "string") {
        inputDate = new Date(inputDate);
    }
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1
    const day = String(inputDate.getDate()).padStart(2, '0');

    // Format the date as 'YYYY-MM-DD'
    return `${year}-${month}-${day}`;
}

export function longDate(inputDate) {
    const options = {    
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    };
  
    if (typeof inputDate === "string") {
        inputDate = new Date(inputDate);
    }

    return inputDate ? inputDate.toLocaleDateString('en-US', options) : null;
}

export function longDateWithWeekDay(inputDate) {
    if (typeof inputDate === "string") {
        inputDate = new Date(inputDate);
    }
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    return inputDate ? inputDate.toLocaleDateString('en-US', options) : null;
}

export function dateWithWeekDay(inputDate) {
    const { locale } = useI18n()

    if (typeof inputDate === "string") {
        inputDate = new Date(inputDate);
    }
    
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    return inputDate 
        ? inputDate.toLocaleDateString(locale.value, options).replaceAll('de ', '').replace('.', '')
        : null;
}

export function durationFormat(inputTime) {
    if (!/^\d{2}:\d{2}:\d{2}$/.test(inputTime)) return inputTime

    const parts = inputTime.split(':');
    return `${parts[0]}:${parts[1]}h`;
}

export function formatDurationHours(hoursArray) {
    if (hoursArray.length === 0) {
        return null
    }

    const uniqueHours = Array.from(
        new Set(hoursArray.map(hour => String(hour)))
    )

    const sortedHours = uniqueHours.sort()

    const formatTime = (time) => {
        const [hour, minutes] = time.split(':');
        return minutes === '00' ? hour.replace(/^0/, '') : `${hour.replace(/^0/, '')}:${minutes}`
    }
  
    if (sortedHours.length === 1) {
        return `${formatTime(sortedHours[0])}h`
    }
  
    const minTime = formatTime(sortedHours[0])
    const maxTime = formatTime(sortedHours[sortedHours.length - 1])
  
    return `${minTime}-${maxTime}h`
}

export function isToday(dateToCheck) {
    const today = new Date();
    if (typeof dateToCheck === "string") {
        dateToCheck = new Date(dateToCheck);
    }

    return (
        dateToCheck.getDate() === today.getDate() &&
        dateToCheck.getMonth() === today.getMonth() &&
        dateToCheck.getFullYear() === today.getFullYear()
    );
}

export function isDateGreaterThan(date1, date2) {
    if (typeof date1 === "string") {
        date1 = new Date(date1);
    }
    if (typeof date2 === "string") {
        date2 = new Date(date2);
    }
    return date1.getTime() > date2.getTime();
}

export const timeOptions = [
    "00:00", "00:05", "00:10", "00:15", "00:20", "00:25", "00:30", "00:35", "00:40", "00:45", "00:50", "00:55", 
    "01:00", "01:05", "01:10", "01:15", "01:20", "01:25", "01:30", "01:35", "01:40", "01:45", "01:50", "01:55",
    "02:00", "02:05", "02:10", "02:15", "02:20", "02:25", "02:30", "02:35", "02:40", "02:45", "02:50", "02:55",
    "03:00", "03:05", "03:10", "03:15", "03:20", "03:25", "03:30", "03:35", "03:40", "03:45", "03:50", "03:55",
    "04:00", "04:05", "04:10", "04:15", "04:20", "04:25", "04:30", "04:35", "04:40", "04:45", "04:50", "04:55",
    "05:00", "05:05", "05:10", "05:15", "05:20", "05:25", "05:30", "05:35", "05:40", "05:45", "05:50", "05:55",
    "06:00", "06:05", "06:10", "06:15", "06:20", "06:25", "06:30", "06:35", "06:40", "06:45", "06:50", "06:55",
    "07:00", "07:05", "07:10", "07:15", "07:20", "07:25", "07:30", "07:35", "07:40", "07:45", "07:50", "07:55",
    "08:00", "08:05", "08:10", "08:15", "08:20", "08:25", "08:30", "08:35", "08:40", "08:45", "08:50", "08:55",
    "09:00", "09:05", "09:10", "09:15", "09:20", "09:25", "09:30", "09:35", "09:40", "09:45", "09:50", "09:55",
    "10:00", "10:05", "10:10", "10:15", "10:20", "10:25", "10:30", "10:35", "10:40", "10:45", "10:50", "10:55",
    "11:00", "11:05", "11:10", "11:15", "11:20", "11:25", "11:30", "11:35", "11:40", "11:45", "11:50", "11:55",
    "12:00", "12:05", "12:10", "12:15", "12:20", "12:25", "12:30", "12:35", "12:40", "12:45", "12:50", "12:55",
    "13:00", "13:05", "13:10", "13:15", "13:20", "13:25", "13:30", "13:35", "13:40", "13:45", "13:50", "13:55",
    "14:00", "14:05", "14:10", "14:15", "14:20", "14:25", "14:30", "14:35", "14:40", "14:45", "14:50", "14:55",
    "15:00", "15:05", "15:10", "15:15", "15:20", "15:25", "15:30", "15:35", "15:40", "15:45", "15:50", "15:55",
    "16:00", "16:05", "16:10", "16:15", "16:20", "16:25", "16:30", "16:35", "16:40", "16:45", "16:50", "16:55",
    "17:00", "17:05", "17:10", "17:15", "17:20", "17:25", "17:30", "17:35", "17:40", "17:45", "17:50", "17:55",
    "18:00", "18:05", "18:10", "18:15", "18:20", "18:25", "18:30", "18:35", "18:40", "18:45", "18:50", "18:55",
    "19:00", "19:05", "19:10", "19:15", "19:20", "19:25", "19:30", "19:35", "19:40", "19:45", "19:50", "19:55",
    "20:00", "20:05", "20:10", "20:15", "20:20", "20:25", "20:30", "20:35", "20:40", "20:45", "20:50", "20:55",
    "21:00", "21:05", "21:10", "21:15", "21:20", "21:25", "21:30", "21:35", "21:40", "21:45", "21:50", "21:55",
    "22:00", "22:05", "22:10", "22:15", "22:20", "22:25", "22:30", "22:35", "22:40", "22:45", "22:50", "22:55",
    "23:00", "23:05", "23:10", "23:15", "23:20", "23:25", "23:30", "23:35", "23:40", "23:45", "23:50", "23:55"
]

export function timeOptionsWithKeys() {
    let response = {}
    for (let time of timeOptions) {
        response[time] = time
    }
    
    return response
}

export function incrementDateByHours(date, hoursToIncrement) {
    if (typeof date === "string") {
        date = new Date(date);
    }

    let responseDate = date
    responseDate.setHours(responseDate.getHours() + hoursToIncrement);
    return responseDate
}

export function decreaseDateByHours(date, hoursToDecrease) {
    if (typeof date === "string") {
        date = new Date(date);
    }

    let responseDate = date
    responseDate.setHours(responseDate.getHours() - hoursToDecrease);
    return responseDate
}

export function localTimeDate(date) {
    const { t, locale } = useI18n()

    if (typeof date === "string") {
        date = new Date(date);
    }

    let localTimeString24h = date.toLocaleString(locale.value, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
    });

    localTimeString24h = localTimeString24h.replace('24:', '00:')

    const formattedDate2 = `${localTimeString24h} ${t('common.local_time')} ${date.toLocaleString(locale.value, {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
    })}`;

    return formattedDate2
}

export function convertDateFormatMMYYYY(inputDate) {
    const date = new Date(inputDate);
    
    const year = date.getFullYear() % 100;
    const month = date.getMonth() + 1;
    
    const formattedMonth = month < 10 ? `0${month}` : month;
    
    return `${formattedMonth}/${year}`;
}

// this function returns a date in this format
// 21 August 2023, 11:45 AM
export function dateFormat2(date) {
    if (typeof date === "string" || !date) {
        date = new Date(date);
    }

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const period = date.getHours() < 12 ? 'AM' : 'PM';

    return `${day} ${month} ${year}, ${hours}:${minutes} ${period}`;
}

export function dateFormat3(date) {
    if (typeof date === "string") {
        date = new Date(date);
    }

    const day = `0${date.getDate()}`.slice(-2);
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const year = date.getFullYear();
    const hours = `0${date.getHours()}`.slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const period = 'h';

    return `${day}-${month}-${year} | ${hours}:${minutes}${period}`;
}

export function getMonthAndDay(date) {
    if(date === null) return [null, null]

    if (typeof date === "string") {
        date = new Date(date);
    }

    return [date.getMonth()+1, "" + date.getDate()];
}

export function parseHour(hourString) {
    const [hour, minute] = hourString.split(':').map(Number);
    const date = new Date();
    date.setHours(hour);
    date.setMinutes(minute);
    date.setSeconds(0);
    return date;
}

export function formatHour(date) {
    return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
}

export function generateHoursInterval(hourStart, hourEnd, minutesInterval) {
    const startTime = parseHour(hourStart);
    const endTime = parseHour(hourEnd);

    let intervals = [];
    let currentTime = new Date(startTime);
    while (currentTime <= endTime) {
        intervals.push(formatHour(currentTime));
        currentTime.setMinutes(currentTime.getMinutes() + minutesInterval);
    }
    return intervals;
}

export function generateDateIntervals(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dateArray = [];

    if (start > end) {
        return [];
    }

    let currentDate = start;

    while (currentDate <= end) {
        dateArray.push(currentDate.toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
}

export function getDayName(date) {
    const options = { weekday: 'long' };

    return new Date(date).toLocaleDateString('en-US', options).toLowerCase();
}

export function formatDate(date, separator = '/') {
    const [year, month, day] = date.split('-')

    return `${day}${separator}${month}${separator}${year}`
}

export function getCurrentDate() {
    const date = new Date()

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`;
}

export function addYear(date) {
    date.setFullYear(date.getFullYear() + 1)

    return new Date(date)
}

export const timeOptions5Minutes = {
    "00:00h": "00:00h", "00:05h": "00:05h", "00:10h": "00:10h", "00:15h": "00:15h", "00:20h": "00:20h", "00:25h": "00:25h", "00:30h": "00:30h", "00:35h": "00:35h", "00:40h": "00:40h", "00:45h": "00:45h", "00:50h": "00:50h", "00:55h": "00:55h",
    "01:00h": "01:00h", "01:05h": "01:05h", "01:10h": "01:10h", "01:15h": "01:15h", "01:20h": "01:20h", "01:25h": "01:25h", "01:30h": "01:30h", "01:35h": "01:35h", "01:40h": "01:40h", "01:45h": "01:45h", "01:50h": "01:50h", "01:55h": "01:55h",
    "02:00h": "02:00h", "02:05h": "02:05h", "02:10h": "02:10h", "02:15h": "02:15h", "02:20h": "02:20h", "02:25h": "02:25h", "02:30h": "02:30h", "02:35h": "02:35h", "02:40h": "02:40h", "02:45h": "02:45h", "02:50h": "02:50h", "02:55h": "02:55h",
    "03:00h": "03:00h", "03:05h": "03:05h", "03:10h": "03:10h", "03:15h": "03:15h", "03:20h": "03:20h", "03:25h": "03:25h", "03:30h": "03:30h", "03:35h": "03:35h", "03:40h": "03:40h", "03:45h": "03:45h", "03:50h": "03:50h", "03:55h": "03:55h",
    "04:00h": "04:00h", "04:05h": "04:05h", "04:10h": "04:10h", "04:15h": "04:15h", "04:20h": "04:20h", "04:25h": "04:25h", "04:30h": "04:30h", "04:35h": "04:35h", "04:40h": "04:40h", "04:45h": "04:45h", "04:50h": "04:50h", "04:55h": "04:55h",
    "05:00h": "05:00h", "05:05h": "05:05h", "05:10h": "05:10h", "05:15h": "05:15h", "05:20h": "05:20h", "05:25h": "05:25h", "05:30h": "05:30h", "05:35h": "05:35h", "05:40h": "05:40h", "05:45h": "05:45h", "05:50h": "05:50h", "05:55h": "05:55h",
    "06:00h": "06:00h", "06:05h": "06:05h", "06:10h": "06:10h", "06:15h": "06:15h", "06:20h": "06:20h", "06:25h": "06:25h", "06:30h": "06:30h", "06:35h": "06:35h", "06:40h": "06:40h", "06:45h": "06:45h", "06:50h": "06:50h", "06:55h": "06:55h",
    "07:00h": "07:00h", "07:05h": "07:05h", "07:10h": "07:10h", "07:15h": "07:15h", "07:20h": "07:20h", "07:25h": "07:25h", "07:30h": "07:30h", "07:35h": "07:35h", "07:40h": "07:40h", "07:45h": "07:45h", "07:50h": "07:50h", "07:55h": "07:55h",
    "08:00h": "08:00h", "08:05h": "08:05h", "08:10h": "08:10h", "08:15h": "08:15h", "08:20h": "08:20h", "08:25h": "08:25h", "08:30h": "08:30h", "08:35h": "08:35h", "08:40h": "08:40h", "08:45h": "08:45h", "08:50h": "08:50h", "08:55h": "08:55h",
    "09:00h": "09:00h", "09:05h": "09:05h", "09:10h": "09:10h", "09:15h": "09:15h", "09:20h": "09:20h", "09:25h": "09:25h", "09:30h": "09:30h", "09:35h": "09:35h", "09:40h": "09:40h", "09:45h": "09:45h", "09:50h": "09:50h", "09:55h": "09:55h",
    "10:00h": "10:00h", "10:05h": "10:05h", "10:10h": "10:10h", "10:15h": "10:15h", "10:20h": "10:20h", "10:25h": "10:25h", "10:30h": "10:30h", "10:35h": "10:35h", "10:40h": "10:40h", "10:45h": "10:45h", "10:50h": "10:50h", "10:55h": "10:55h",
    "11:00h": "11:00h", "11:05h": "11:05h", "11:10h": "11:10h", "11:15h": "11:15h", "11:20h": "11:20h", "11:25h": "11:25h", "11:30h": "11:30h", "11:35h": "11:35h", "11:40h": "11:40h", "11:45h": "11:45h", "11:50h": "11:50h", "11:55h": "11:55h",
    "12:00h": "12:00h", "12:05h": "12:05h", "12:10h": "12:10h", "12:15h": "12:15h", "12:20h": "12:20h", "12:25h": "12:25h", "12:30h": "12:30h", "12:35h": "12:35h", "12:40h": "12:40h", "12:45h": "12:45h", "12:50h": "12:50h", "12:55h": "12:55h",
    "13:00h": "13:00h", "13:05h": "13:05h", "13:10h": "13:10h", "13:15h": "13:15h", "13:20h": "13:20h", "13:25h": "13:25h", "13:30h": "13:30h", "13:35h": "13:35h", "13:40h": "13:40h", "13:45h": "13:45h", "13:50h": "13:50h", "13:55h": "13:55h",
    "14:00h": "14:00h", "14:05h": "14:05h", "14:10h": "14:10h", "14:15h": "14:15h", "14:20h": "14:20h", "14:25h": "14:25h", "14:30h": "14:30h", "14:35h": "14:35h", "14:40h": "14:40h", "14:45h": "14:45h", "14:50h": "14:50h", "14:55h": "14:55h",
    "15:00h": "15:00h", "15:05h": "15:05h", "15:10h": "15:10h", "15:15h": "15:15h", "15:20h": "15:20h", "15:25h": "15:25h", "15:30h": "15:30h", "15:35h": "15:35h", "15:40h": "15:40h", "15:45h": "15:45h", "15:50h": "15:50h", "15:55h": "15:55h",
    "16:00h": "16:00h", "16:05h": "16:05h", "16:10h": "16:10h", "16:15h": "16:15h", "16:20h": "16:20h", "16:25h": "16:25h", "16:30h": "16:30h", "16:35h": "16:35h", "16:40h": "16:40h", "16:45h": "16:45h", "16:50h": "16:50h", "16:55h": "16:55h",
    "17:00h": "17:00h", "17:05h": "17:05h", "17:10h": "17:10h", "17:15h": "17:15h", "17:20h": "17:20h", "17:25h": "17:25h", "17:30h": "17:30h", "17:35h": "17:35h", "17:40h": "17:40h", "17:45h": "17:45h", "17:50h": "17:50h", "17:55h": "17:55h",
    "18:00h": "18:00h", "18:05h": "18:05h", "18:10h": "18:10h", "18:15h": "18:15h", "18:20h": "18:20h", "18:25h": "18:25h", "18:30h": "18:30h", "18:35h": "18:35h", "18:40h": "18:40h", "18:45h": "18:45h", "18:50h": "18:50h", "18:55h": "18:55h",
    "19:00h": "19:00h", "19:05h": "19:05h", "19:10h": "19:10h", "19:15h": "19:15h", "19:20h": "19:20h", "19:25h": "19:25h", "19:30h": "19:30h", "19:35h": "19:35h", "19:40h": "19:40h", "19:45h": "19:45h", "19:50h": "19:50h", "19:55h": "19:55h",
    "20:00h": "20:00h", "20:05h": "20:05h", "20:10h": "20:10h", "20:15h": "20:15h", "20:20h": "20:20h", "20:25h": "20:25h", "20:30h": "20:30h", "20:35h": "20:35h", "20:40h": "20:40h", "20:45h": "20:45h", "20:50h": "20:50h", "20:55h": "20:55h",
    "21:00h": "21:00h", "21:05h": "21:05h", "21:10h": "21:10h", "21:15h": "21:15h", "21:20h": "21:20h", "21:25h": "21:25h", "21:30h": "21:30h", "21:35h": "21:35h", "21:40h": "21:40h", "21:45h": "21:45h", "21:50h": "21:50h", "21:55h": "21:55h",
    "22:00h": "22:00h", "22:05h": "22:05h", "22:10h": "22:10h", "22:15h": "22:15h", "22:20h": "22:20h", "22:25h": "22:25h", "22:30h": "22:30h", "22:35h": "22:35h", "22:40h": "22:40h", "22:45h": "22:45h", "22:50h": "22:50h", "22:55h": "22:55h",
    "23:00h": "23:00h", "23:05h": "23:05h", "23:10h": "23:10h", "23:15h": "23:15h", "23:20h": "23:20h", "23:25h": "23:25h", "23:30h": "23:30h", "23:35h": "23:35h", "23:40h": "23:40h", "23:45h": "23:45h", "23:50h": "23:50h", "23:55h": "23:55h"
}