<script setup>
import ButtonComponent from '@/components/ButtonComponent.vue';
import SvgComponent from '@/components/SvgComponent.vue';

const emit = defineEmits(['close']);

const props = defineProps({
    showModal: { type: Boolean, default: false },
    width: { String, default: '439px' }
})

function emitClose() {
    emit('close')
}
</script>

<template>
    <div class="modal-outter-wrap" v-if="showModal">
        <div class="overlay" @click="emitClose()"></div>
        <div class="modal2" :style="{maxWidth: width}">
            <ButtonComponent class="close-modal" color="transparent" simple :size="{ width: '27px', height: '27px' }" @click="emitClose()">
                <SvgComponent name="IconClose" />
            </ButtonComponent>

            <div class="modal-content modal-scroll" id="modal-scrollable-section">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.modal-outter-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.7;
        background: #000;
    }

    .modal2 {
        width: 100%;
        max-width: 439px;
        box-sizing: border-box;
        background: #fff;
        position: relative;

        @media (min-width:768px) {
            padding: 27px 0 27px 0;
            border-radius: 12px;
            max-height: calc(100vh - 50px);
        }

        @media (max-width:767px) {
            padding: 55px 0;
            max-height: 100vh;
        }

        @media (max-width:575px) {
            top: 0;
            left: 0;
            max-width: 100%;
            height: 100vh;
            transform: translate(0, 0);
        }

        .close-modal {
            position: absolute;
            right: 14px;
            z-index: 2;

            @media (min-width:768px) {
                top: 19px;
                right: 19px;
            }

            @media (max-width:767px) {
                top: 16px;
                right: 16px;
            }
        }
    }

    .modal-content {
        @media (min-width:768px) {
            padding: 0 40px;
        }

        @media (max-width:767px) {
            padding: 0 32px;
        }
    }
}

#modal-scrollable-section {
    max-height: calc(100vh - 105px);
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}
</style>