import ApiManager from "@/helpers/ApiManager"
import router from "@/router"
import axios from "axios"

const defaultLocale = localStorage.getItem('locale') || 'en'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common["Accept-Language"] = defaultLocale

const state = {
    user: JSON.parse(localStorage.getItem('user')) || null,
    bookings: [],
    bookingItemsByCategory: [],
    loginForm: {
        email: "",
        password: "",
        rememberMe: 0
    },
    signupForm: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        country: '',
        birthdate: '',
        description: '',
        vatnumber: '',
        address: '',
        zipcode: '',
        password: '',
        confirm_password: '',
    },
    errors: {},
    requestError: null,
    bookingsOrderBy: "desc",
    fetching_bookings: false,
    unreadMessages: 0,
    isProcessing: false,
    locale: defaultLocale,
    userPoints: null,
    giftCards: [],
}

const getters = {
    getForm: state => state.loginForm,
    getSignupForm: state => state.signupForm,
    getFormErrors: state => state.errors,
    getApiKey: state => state.user?.api_token ?? null,
    getIsLoggedIn: state => state.user?.api_token ? true : false,
    getUser: state => state.user,
    getPlatformCities: (state, getters, rootState, rootGetters) => {
        if (!state.signupForm.country || !rootGetters['frontend/getPlatformCountries'][state.signupForm.country]) {
            return {}
        }
        return rootGetters['frontend/getPlatformCountries'][state.signupForm.country].cities.reduce((accumulator, value) => {
            return {...accumulator, [value]: value}
        }, {})
    },
    getUserSettings: (state, getters) => {
        const user = getters.getUser

        return {
            ...user,
            ...user?.profile,
            ...user?.company,
            contact: user.phone,
            first_name: user.first_name,
            last_name: user.last_name,
            vat: user?.profile?.vatnumber ?? '',
            iban: user?.profile?.iban ?? user?.company?.iban,
            bicswift: user?.profile?.bicswift ?? user?.company?.bicswift,
        }
    },
    getDataForProfileForm: (state) => {
        let data = {
            firstName: state.user.first_name,
            lastName: state.user.last_name,
            password: '',
            confirmPassword: '',
            phone: state.user.phone,
            country: "pt"
        }
        if (state.user.company) {
            const modules = JSON.parse(state.user.company.modules)
            
            data = {
                ...data,
                isExperience: modules.experiences == 1 ? true: false,
                isTransfer: modules.transfers == 1 ? true: false,
                businessName: state.user.company.business_name,
                taxNumber: state.user.company.tax_number,
                iban: state.user.company.iban,
                bicswift: state.user.company.bicswift,
                address: state.user.company.address,
                logo: state.user.company.logos.map((logo) => logo.url),
                zipCode: state.user.company.zip_code,
                city: state.user.company.city,
                numberOfEmployees: state.user.company.number_of_employees,
                tradeName: state.user.company.trade_name,
                description: state.user.company.description,
                website: state.user.company.website,
                email: state.user.company.email
            }
        }
        return data;
    },
    getCompanyType: (state) => {
        if(!state.user || !state.user.company || !state.user.company.company_type) {
            return null
        }
        return state.user.company.company_type
    },
    isSupplier: state => state.user && state.user.user_type === 'SUPPLIER',
    isSuper: state => state.user && state.user.user_type === 'SUPER',
    isClient: state => state.user && state.user.user_type === 'CLIENT',
    isAffiliate: state => state.user && state.user.user_type === 'AFFILIATE',
    getUserBookings: state => state.bookings ?? [],
    getUserBookingItemsByCategory: state => state.bookingItemsByCategory ?? [],
    getUserBookingsOrderBy: state => state.bookingsOrderBy,
    getIsFetchingBookings: state => state.fetching_bookings,
    getUnreadMessages: state => state.unreadMessages,
    getIsProcessing: state => state.isProcessing,
    getLocale: state => state.locale,
    getUserPoints: state => state.userPoints,
    getUserGiftCards: state => state.giftCards ?? [],
}

const actions = {
    setFormData: ({ commit }, payload) => {
        commit('RESET_FIELD_ERROR', payload)
        commit('SET_FORM_DATA', payload)
    },
    setSignupFormData: ({ commit }, payload) => {
        commit('RESET_FIELD_ERROR', payload)
        commit('SET_SIGNUP_FORM_DATA', payload)
    },
    resetErrors: ({ commit }) => {
        commit("SET_ERRORS", {})
    },
    login: ({commit, state, dispatch}) => {
        commit('SET_PROCESSING', true)

        const payload = state.loginForm
        return new Promise((resolve, reject) => {
            axios.post("/api/login", payload)
            .then((response) => {
                const data = response.data
                if (data.status) {
                    commit('SET_LOGIN_INFO', data.content)

                    if (data.content.user_type === "SUPPLIER") {
                        dispatch('checkout/deleteCart', null, { root: true })
                    } else {
                        dispatch('checkout/updateDatabaseCart', null, { root: true })
                        dispatch('checkout/setInitialBillingData', data.content, { root: true })
                    }
                    
                    return resolve(data)
                } else {
                    if(data.field) {
                        return commit("SET_ERRORS", {[(data.field)]: [data.message]})
                    }
                    commit("SET_ERRORS", data)
                }
            })
            .catch(error => {
                commit('SET_LOGIN_REQUEST_ERROR', error)
                reject(error)
            }).finally(() => {
                commit('SET_PROCESSING', false)
            })
        })
    },
    logout: ({commit}) => {
        // ApiManager.post('api/logout').then(() => {
            commit('EXECUTE_LOGOUT')
        // })
    },
    signup: ({commit, state, dispatch}) => {
        commit('SET_PROCESSING', true)

        const payload = state.signupForm
        return new Promise((resolve, reject) => {
            ApiManager.post('api/clients', payload).then(response => {
                const data = response.data
                
                if (!response.errors && data.status) {
                    commit('SET_LOGIN_INFO', data.content)
                    dispatch('checkout/setInitialBillingData', data.content, { root: true })
                    dispatch('checkout/updateDatabaseCart', null, { root: true })
                } else {
                    if (response.errors) {
                        return commit("SET_ERRORS", response.errors)
                    }
                    commit("SET_ERRORS", response.errors)
                    reject(response)
                }

                return resolve(data)
            }).catch(error => {
                commit('SET_LOGIN_REQUEST_ERROR', error)
                reject(error)
            }).finally(() => {
                commit('SET_PROCESSING', false)
            })
        })
    },
    update: ({commit, state, dispatch}) => {
        const payload = state.signupForm
        return new Promise((resolve, reject) => {
            ApiManager.post('api/clients/'+state.user.id, payload).then(response => {
                const data = response.data
                
                if (!response.errors && data.status) {
                    commit('UPDATE_USER_SESSION', {...state.user, ...data.content, ...data.content?.profile })
                    dispatch('checkout/setInitialBillingData', data.content, { root: true})
                } else {
                    if (response.errors) {
                        return commit("SET_ERRORS", response.errors)
                    }
                    commit("SET_ERRORS", response.errors)
                    reject(response)
                }
                return resolve(data)
            }).catch(error => {
                commit('SET_LOGIN_REQUEST_ERROR', error)
                reject(error)
            })
        })
    },
    fetchLoginInfo: async ({commit, state}) => {
        await ApiManager.get(`api/user/${state.user.id}`).then((response) => {
            if (response.data.status) {
                response.data.content.api_token = state.user.api_token
                commit("SET_LOGIN_INFO", response.data.content);
            }
        });
    },
    fetchUserBookings: ({commit, state}, payload = {}) => {
        commit('SET_USER_BOOKINGS', [])
        commit('FETCHING_BOOKINGS', true)
        let apiUrl = 'api/client-bookings';
        switch (router.currentRoute.value.href) {
            case '/user-panel/bookings':
                payload.status = ['waiting_payment', 'pending', 'confirmed']
                break;
        
            case '/user-panel/bookings/previous':
                payload.status = ['completed']
                break;
            
            case '/user-panel/bookings/canceled':
                payload.status = ['declined', 'cancelled']
                break;
            default:
                apiUrl = 'api/client-book-items/by-category'
                payload.default = true
        }

        if(!payload.orderBy) {
            payload.orderBy = state.bookingsOrderBy
        }
        ApiManager.get(apiUrl, {
            params: payload
        }).then(response => {
            const data = response.data
            if(payload.default) {
                commit('SET_BOOKING_ITEMS_BY_CATEGORY', data.content)
                commit('FETCHING_BOOKINGS', false)
                return
            }
            commit('SET_USER_BOOKINGS', data)
            commit('FETCHING_BOOKINGS', false)
        })
    },
    preSetSettings: ({state}) => {
        state.signupForm = { ...state.signupForm, ...state.user }
    },
    updateCover: ({commit, state}, payload) => {
        return new Promise((resolve, reject) => {
            ApiManager.post('/api/clients/updateCover', payload).then((response) => {
                const data = response.data;
                if (data.status) {
                    if (! payload.get('impersonate_id')) {
                        commit('UPDATE_USER_SESSION', {...state.user, ...data.content, ...data.content?.profile })
                    }
                    
                    return resolve(data)
                }
                return reject(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    updateAvatar: ({commit, state}, payload) => {
        return new Promise((resolve, reject) => {
            ApiManager.post('/api/clients/updateAvatar', payload).then((response) => {
                const data = response.data;
                if (data.status) {
                    if (! payload.get('impersonate_id')) {
                        commit('UPDATE_USER_SESSION', {...state.user, ...data.content, ...data.content?.profile })
                    }

                    return resolve(data)
                }
                return reject(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    validateUser: (context, payload) => {
        return new Promise((resolve, reject) => {
            ApiManager.post('/api/verify-client-register', payload).then((response) => {
                const data = response.data;
                if(data.status) {
                    return resolve(data)
                }
                return reject(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    resendValidateUser: (context, payload) => {
        return new Promise((resolve, reject) => {
            ApiManager.post('/api/email-client-register', payload).then((response) => {
                const data = response.data;
                if(data.status) {
                    return resolve(data)
                }
                return reject(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    fetchUser: async ({ dispatch }, id) => {
        const result = await ApiManager.get(`api/user/${id}`).then((response) => {
            if (response?.data?.status) {
                return response.data.content
            }

            return false
        })

        return result
    },
    fetchUnreadMessages: async ({commit, state}) => {
        const response = await ApiManager.get('api/unread-messages')

        if (response?.data?.status) {
            commit('SET_UNREAD_MESSAGES', response.data.content)
            return true
        }

        return false
    },
    setLocale: ({ commit }, payload) => {
        commit('SET_LOCALE', payload)
    },
    fetchUserPoints: async ({commit, state}) => {
        const response = await ApiManager.get('api/user-points')

        if (response?.data?.status) {
            commit('SET_USER_POINTS', response.data.content)
            return true
        }

        return false
    },
    fetchUserGiftCards: ({ commit, state }, payload = {}) => {
        commit('SET_USER_GIFT_CARDS', [])
        if (!payload.orderBy) {
            payload.orderBy = state.bookingsOrderBy
        }

        ApiManager.get('api/client-gift-cards', { params: payload }).then(response => {
            commit('SET_USER_GIFT_CARDS', response.data)
        })
    },
}

const mutations = {
    SET_FORM_DATA: (state, formData) => {
        state.loginForm = {
            ...state.loginForm,
            ...formData
        }
    },
    SET_SIGNUP_FORM_DATA: (state, formData) => {
        state.signupForm = {
            ...state.signupForm,
            ...formData
        }
    },
    SET_ERRORS: (state, errors) => {
        Object.entries(errors).map(error => {
            const [key, value]  = error
            const keyPieces     = key.split('.')

            if(keyPieces[1] && !isNaN(Number(keyPieces[1]))) {
                if(!errors[keyPieces[0]]) {
                    errors[keyPieces[0]] = []
                }
                errors[keyPieces[0]].push(String(value).replace(key, keyPieces[0]))
            }
        })
        state.errors = errors
    },
    RESET_FIELD_ERROR: (state, field) => {
        let [key] = Object.keys(field)
        if(Object.keys(state.errors).includes(key))
            delete state.errors[key]
    },
    SET_LOGIN_INFO: (state, user) => {
        state.user = {...user, ...user.profile }
        localStorage.setItem('user', JSON.stringify(state.user))
    },
    UPDATE_USER_SESSION: (state, user) => {
        state.user = {...state.user, ...user}
        localStorage.setItem('user', JSON.stringify(state.user))
    },
    SET_LOGIN_REQUEST_ERROR: (state, error) => {
        state.requestError = error
    },
    SET_USER_BOOKINGS: (state, data) => {
        state.bookings = data
    },
    FETCHING_BOOKINGS: (state, data) => {
        state.fetching_bookings = data
    },
    EXECUTE_LOGOUT: (state) => {
        const isOnBoardHome = localStorage.getItem('onboarding-home')
        const isOnBoardService = localStorage.getItem('onboarding-service')

        localStorage.clear()
        sessionStorage.clear()

        if (isOnBoardHome) {
            localStorage.setItem('onboarding-home', true)
        }

        if (isOnBoardService) {
            localStorage.setItem('onboarding-service', true)
        }
        
        state.user = null
    },
    SET_BOOKING_ORDER_BY: (state, newValue) => {
        state.bookingsOrderBy = newValue
    },
    SET_BOOKING_ITEMS_BY_CATEGORY: (state, data) => {
        state.bookingItemsByCategory = data
    },
    SET_UNREAD_MESSAGES: (state, data) => {
        state.unreadMessages = data
    },
    SET_PROCESSING: (state, status) => {
        state.isProcessing = status
    },
    SET_LOCALE: (state, locale) => {
        state.locale = locale
        localStorage.setItem('locale', locale)
    },
    SET_USER_POINTS: (state, points) => {
        state.userPoints = points
    },
    SET_USER_GIFT_CARDS: (state, data) => {
        state.giftCards = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}